<template>
	<div>
		<div class="read_box">
			<div class="read_box1">
				<div class="read_mainbox">
					<div class="epubread_left">
						<div class="read_exitbtn">
							<a href="javascript:void(0)" @click="exitRead()">退出阅读</a>
						</div>
						<div class="epubread_intro" v-if="JournalModelInfo">
							<h2>{{JournalModelInfo.EntryModel.JournalName}}</h2>
							<div class="epubread_introzy" v-if="JournalModelInfo.EntryModel.Summary">
								<div class="zpxxTitle_box m_0">
									<i></i>
									<span>摘要</span>
								</div>
								<p v-html="JournalModelInfo.EntryModel.Summary"></p>
							</div>
							<div class="epubread_introgjc" v-if="JournalModelInfo.Keywords.length > 0">
								<div class="zpxxTitle_box m_0">
									<i></i>
									<span>关键词</span>
								</div>
								<font>
									<a href="javascript:void(0)" :style="value.type == 0 || value.ReleaseStatus == 0?'color:#000; cursor: default;':'color:#99533e'" @click="toElementDetail(value)" v-for="(value,index) in JournalModelInfo.Keywords" :key="index">{{value.keyword}}</a>
								</font>
							</div>
						</div>
					</div>
					<div class="epubread_readbox">
						<div class="epubread_readbox_top" v-if="JournalModelInfo">
							<a style="cursor: pointer;" class="readtype_selected" href="javascript:void(0)">EPUB阅读</a>
							<a v-if="JournalModelInfo.EntryModel.PDFPath" href="javascript:void(0)" @click="toPDFRead()">PDF阅读</a>
						</div>
						<div class="epubread_readbox_content">
							<div class="epubread_readbox_content1">
								<iframe width="100%" frameborder="0" id="epubContent" style="width: calc( 100% - 60px ); height: calc( 100% - 100px ); position: absolute; left: 30px; top: 5%;" name="manger"></iframe>
							</div>
						</div>
		
						<div class="theme_box">
							<div class="theme_box1">
								<a href="javascript:void(0);"></a>
							</div>
							<div class="theme_box2">
								<a href="javascript:void(0);"></a>
							</div>
							<div class="theme_box3">
								<a href="javascript:void(0);"></a>
							</div>
							<div class="theme_box4">
								<a href="javascript:void(0);"></a>
							</div>
						</div>
						<div class="catalog_box">
							<div class="catalog_boxtitle">
								<div class="catalog_boxtitle1">
									<i></i>
									<span>目录</span>
									<i></i>
								</div>
								<div class="catalog_boxtlist">
									<ul>
										<li v-for="(value,index) in EpubTocList" :key="index">
											<a href="javascript:void(0)" @click="toCatalogIndex(index)">{{value.Name}}</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="read_menubtn">
						<div class="read_changetheme">
							<a href="javascript:void(0);">
								<i></i>
								<span>换肤</span>
							</a>
						</div>
						<div class="read_catalog">
							<a href="javascript:void(0);">
								<i></i>
								<span>目录</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="login_footer" style="float: left;">
			<p>ICP备案信息 <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备2024012976号-2</a></p>
			<p>© 版权所有 甘肃教育出版社有限责任公司 保留所有权利</p>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import CheckInService from '../../../api/CheckInService.js'
	export default{
		name:'JournalReadEpub',
		data(){
			return{
				JournalModelInfo:'',
				EpubTocList:[]
			}
		},
		methods:{ 
			GetJournalModel(){ //获取期刊详情
				var that = this
				CheckInService.GetJournalModel(that.$route.query.id,that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.JournalModelInfo = res.Result
						that.GetEpubToc()
					}
				})
			},
			GetEpubToc(){ //获取epub内容
				var that = this
				CheckInService.GetJournalEpubToc(that.$route.query.id).then(res=>{
					if(res.Tag == 1){
						that.EpubTocList = res.Result
						$(".read_catalog a").click(function() {
							$(".catalog_box").toggle(700)
						})
						setTimeout(function() {
							$("#epubContent").contents().find("body").append("<style>img{ max-width:96%;height:auto;}</style>");
						}, 300)
						$("#epubContent").attr("src", that.EpubTocList[0].Url)
					}
				})
			},
			toCatalogIndex(index){
				setTimeout(function() {
					$("#epubContent").contents().find("body").append("<style>img{ max-width:96%;height:auto;}</style>");
				}, 300)
				$("#epubContent").attr("src", this.EpubTocList[index].Url)
				$(".catalog_box").toggle(700)
				window.scrollTo(0,0)
			},
			toElementDetail(value){
				if(value.type == 1 && value.ReleaseStatus != 0){
					window.open(this.$WebUrl + 'ElementDetail?id=' + value.id)
				}
			},
			toPDFRead(){ //pdf阅读
				this.$router.push({
					path:'/JournalReadPDF',
					query:{
						id:this.$route.query.id
					}
				})
			},
			exitRead(){ //退出阅读
				this.$router.push({
					path:'/JournalManagerDetail',
					query:{
						id:this.$route.query.id
					}
				})
			}
		},
		mounted(){
			setTimeout(function(){
				$(".read_changetheme a").click(function() {
					$(".theme_box").toggle(700)
				})
				$(".theme_box1").click(function() {
					$(".epubread_readbox").addClass('themebg1');
					$(".epubread_readbox").removeClass('themebg2');
					$(".epubread_readbox").removeClass('themebg3');
					$(".epubread_readbox").removeClass('themebg4');
				})
				$(".theme_box2").click(function() {
					$(".epubread_readbox").addClass('themebg2');
					$(".epubread_readbox").removeClass('themebg1');
					$(".epubread_readbox").removeClass('themebg3');
					$(".epubread_readbox").removeClass('themebg4');
				})
				$(".theme_box3").click(function() {
					$(".epubread_readbox").addClass('themebg3');
					$(".epubread_readbox").removeClass('themebg1');
					$(".epubread_readbox").removeClass('themebg2');
					$(".epubread_readbox").removeClass('themebg4');
				})
				$(".theme_box4").click(function() {
					$(".epubread_readbox").addClass('themebg4');
					$(".epubread_readbox").removeClass('themebg1');
					$(".epubread_readbox").removeClass('themebg2');
					$(".epubread_readbox").removeClass('themebg3');
				})
			},1000)
			this.GetJournalModel()
		}
	}
</script>

<style>
</style>
